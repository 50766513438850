import 'simplebar/dist/simplebar.min.css';
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';  
import Vue2TouchEvents from 'vue2-touch-events';
import AddToHomescreen from '@owliehq/vue-addtohomescreen';
//tree   trail_tree
//https://naturesurvey.in/backend/index.php/
axios.defaults.baseURL = 'https://naturesurvey.in/backend/index.php/api/';
axios.defaults.headers.common['Authorization'] = (localStorage['token'])?localStorage['token']:'na';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const app = createApp(App);
app.config.globalProperties.$apiname = 'https://naturesurvey.in/backend/index.php/api/';
app.config.globalProperties.$hostname = 'https://naturesurvey.in/backend/'
app.use(router);
app.use(VueSweetalert2);
app.use(Vue2TouchEvents);
app.use(AddToHomescreen, {
  buttonColor: 'blue',
});
app.mount('#app');
//createApp(App).use(store).use(router).mount('#app')
