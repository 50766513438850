<template>

  <!-- Page Sidebar Ends-->
  <div class="page-body">
  
    <PageTitle title="Dashboard" path="frontdash"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row size-column">

        <div class="col-xxl-9 box-col-12">
          <div class="row">
           
            <!--<div class="col-xl-3 col-sm-6"  v-for="(item ,index)  in surveys" :key="item.id"  >
             
              <div class="card o-hidden small-widget" @click="startSurvey(item.id)">
                <div class="card-body total-Progress border-b-warning border-2"> <span class="f-light f-w-500 f-14">{{item.name}}</span>
                  <div class="project-details">
                    <div class="project-counter">
                      <h2 class="f-w-600">{{item.survey_count}}</h2> 
                    </div>
                    <div class="product-sub bg-warning-light">
                      
                    </div>
                  </div>
                  
                </div>
              </div>
          
            </div>-->
            <div class="col-xl-12 col-sm-12"  >
                  <div class="card o-hidden small-widget" >
                    <div class="card-body total-Progress   border-2"> <span class="f-light f-w-500 f-14">{{userDetails.np_name}}</span>
                      <div class="project-details"> 
                        <div class="product-sub bg-warning-light">
                          <i class="fa fa-user"></i>
                        </div>
                        <div class="profcard"> 
                                {{userDetails.empname}} 
                                <div>({{userDetails.empcode}}) &nbsp;&nbsp;<i class="fa fa-phone"></i>{{userDetails.empcontact}}</div>
                        </div>
                      </div>
                    </div>
                  </div> 
            </div>
             <div class="col-xl-3 col-sm-6" v-show="nptype.nptype=='TREE' || nptype.nptype=='BOTH'" >
             <router-link to="/newsurvey">
              <div class="card o-hidden small-widget" >
                <div class="card-body total-Progress border-b-warning border-2"> <span class="f-light f-w-500 f-14"></span>
                  <div class="project-details">
                    <div class="project-counter"> Tree Survey
                    </div>
                    <div class="product-sub bg-warning-light">
                      <i class="fa fa-tree"></i>
                    </div>
                  </div>
                  
                </div>
              </div>
                 </router-link>
            </div>
             <div class="col-xl-3 col-sm-6"  v-show="nptype.nptype=='TANK' || nptype.nptype=='BOTH'"  >
             <router-link to="/tanksurvey">
              <div class="card o-hidden small-widget" >
                <div class="card-body total-Progress border-b-warning border-2"> <span class="f-light f-w-500 f-14"></span>
                  <div class="project-details">
                    <div class="project-counter"> Tank Survey
                    </div>
                    <div class="product-sub bg-warning-light">
                      <i class="fa fa-archive"></i>
                    </div>
                  </div>
                  
                </div>
              </div>
                 </router-link>
            </div>
               <div class="col-xl-3 col-sm-6"  v-show="nptype.nptype=='TANK' || nptype.nptype=='BOTH'"  >
                 <router-link to="/tankreport">
                  <div class="card o-hidden small-widget bg-info" >
                    <div class="card-body total-Progress border-b-warning border-2">
                      <div class="project-details ">
                        <div class="project-counter"> 
                        Tank Report
                        </div>
                        <div class="product-sub bg-info-light">
                          <b>{{tank_surveys_count}}</b>
                        </div>
                      </div>
                      
                    </div>
                  </div>
              </router-link>
            </div>
             
               <div class="col-xl-3 col-sm-6" v-show="nptype.nptype=='TREE' || nptype.nptype=='BOTH'"  >
                 <router-link to="/treereport">
                  <div class="card o-hidden small-widget bg-success" >
                    <div class="card-body total-Progress border-b-warning border-2">
                      <div class="project-details ">
                        <div class="project-counter"> 
                        Survey Report
                        </div>
                        <div class="product-sub bg-warning-light">
                          <b>{{surveys_count}}</b>
                        </div>
                      </div>
                      
                    </div>
                  </div>
              </router-link>
            </div>
            
             <div class="col-xl-3 col-sm-6" v-show="nptype.nptype=='TREE' || nptype.nptype=='BOTH'"  >
                 <router-link to="/treereport?q=t">
                  <div class="card o-hidden small-widget bg-info" >
                    <div class="card-body total-Progress border-b-warning border-2"> <span class="f-light f-w-500 f-14"> </span>
                      <div class="project-details">
                        <div class="project-counter"> Today Report
                        </div>
                        <div class="product-sub bg-warning-light">
                          <b>{{today_report_count}}</b>
                        </div>
                      </div>
                      
                    </div>
                  </div>
              </router-link>
            </div>
                
             <div class="col-xl-3 col-sm-6"  v-show="nptype.nptype=='TREE' || nptype.nptype=='BOTH'"  >
                 <router-link to="/offlinereport">
                     <!--<router-link to="/treereport?q=t">-->
                  <div class="card o-hidden small-widget bg-dark" >
                    <div class="card-body total-Progress border-b-warning border-2"> <span class="f-light f-w-500 f-14"> </span>
                      <div class="project-details">
                        <div class="project-counter"> Offline Report
                        </div>
                        <div class="product-sub bg-warning-light">
                          <b>{{(offline_count)?offline_count:0}}</b>
                        </div>
                      </div>
                      
                    </div>
                  </div>
              </router-link>
            </div>
            
            </div>
             
          </div>
      </div>
      </div>
    </div>
    <!-- Container-fluid Ends--> 

</template>
<style>
    .profcard{
        width: -webkit-fill-available;
        padding-left: 10px;
    }
</style>
<script>
import PageTitle from '@/components/PageTitle.vue';
import axios from 'axios';
import { getAllRecords} from '@/dexie';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale 
} from 'chart.js'
import { Bar } from 'vue-chartjs'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
  name: 'FrontDash',
  components: {
        PageTitle ,Bar,getAllRecords
    },
    mounted() {
        this.getBasics();

    }, data() {
        return {
          today_report_count: 0,
          surveys_count: 0,
          tank_surveys_count: 0,
          offline_count: 0,
          surveys: [],
          userDetails: [],
          entry_dates: [], 
          survey_report: [],
          nptype:[],
          data: {
                labels: this.entry_dates,
                datasets: [{ data: this.purchaseStock},{data: this.saleStock }]
              },
          options: {
  maintainAspectRatio: false,
                responsive: true
              }
        }
    },
    methods: {
      async getBasics() {
            var self = this;
            axios.post('getFrontDash').then(function (response) {
                self.nptype  = response.data.nptype;
                self.surveys  = response.data.surveys;
                self.surveys_count  = response.data.surveys_count;
                self.tank_surveys_count  = response.data.tank_surveys_count;
                self.today_report_count  = response.data.today_report_count;
                self.userDetails  = response.data.userDetails;
                window.scrollTo(0, 0);
            });
            
            
            this.offline_count=getAllRecords().length;
        },
        startSurvey(item_id){
             this.$router.push({ name: "takesurvey" , query: { q: item_id} }); 
        }
    }
}
</script>
