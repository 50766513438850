<template>
    <button class="btn bt-danger" @click="backBtnPress()"><i class="fa fa-arrow-left"></i> Go Back</button>
    
    <div  @click="adjustMap('rotate',20)" style=""  class="hide  btn bg-primary"><i class="fa fa-arrow-left"></i></div>
    <div v-show="!isLoading" @click="refreshLoc" style=""  class=" btn bg-primary"><i class="fa fa-refresh"></i> Refresh</div>
    <div @click="adjustMap('rotate',-20)" style=""  class="hide btn bg-primary"><i class="fa fa-arrow-right"></i></div>
    
    <div  v-show="isLoading" class="spinner-border"  style="float:right" role="status">
      <span class="sr-only">Loading...</span>
    </div>

    
    
   <div class="container-fluid">
    <div ref="map" id="map" style="width: 100%; height: 450px;"   ></div>
    <p>Selected Location: {{ latitude }}, {{ longitude }}</p>
    <div class="row">
         <button  class="btn btn-danger col-4"  @click="backBtnPress()"><i class="fa fa-arrow-left"></i> Go Back</button>   
         <div class="col-4"></div>
         <button  class="btn btn-primary col-4"  @click="captureLocation"><i class="fa fa-check"></i> Confirm</button>
    </div>
  </div>
</template>
<style scoped>
    .spinner-border{
        width: 25px;
        height: 25px;
        color: red;
        margin-right: 5px;
    }
</style>
<script>
import { Loader } from '@googlemaps/js-api-loader';

export default {
     props: {
         last_pins: {
                type: Array,
            },
            userDetails: Array
  },
 
      
  data() {
    return {
      map: null,
      marker: null,
      latitude:0,
      longitude:0,
      isLocationAcepted:false, 
      selectedLocation: {
        lat: null,
        lng: null,
      },
        user_Details:'',
        isLoading:false,
        heading:300
    };
  },
 
  mounted() {
    const loader = new Loader({
      apiKey: 'AIzaSyD9gtJ2oDY7AcqUPTjmER1Ki0ZYC4mbIe0',
      version: 'weekly',
      libraries: ['places'],
    });

    loader.load().then(() => {
      this.getLocation();
    });
    
    this.user_Details=this.userDetails;
    

  },
  methods: {
      
      
       backBtnPress() { 
              this.$emit('backBtnLoc');
            },
       refreshLoc() { 
              this.getLocation();
              this.marker=null;
            },
            
       captureLocation() { 
              this.selectedLocation.lat=this.latitude;
              this.selectedLocation.lng=this.longitude;
              
              
              this.$emit('setLocation', this.selectedLocation);
            },
           getLocation(){
               this.isLoading=true;
                navigator.msMaxTouchPoints = navigator.msMaxTouchPoints || 2;
                navigator.geolocation.getCurrentPosition(this.success, this.error,{
                enableHighAccuracy: true, // Request higher accuracy
                timeout: 5000, // Timeout in milliseconds
                maximumAge: 0 // Don't use cached position
              }); 
          
                      
                      
            },
        adjustMap(mode,amount) {
             
            if(this.map.getHeading())
                this.heading=parseFloat(this.map.getHeading())+parseFloat(amount);
            else 
                this.heading += parseFloat(amount);
            
          
            
            
            switch (mode) {
              case "tilt":
                this.map.setTilt(parseFloat(this.map.getTilt()) + parseFloat(amount));
                break;
              case "rotate":
              //    alert(this.heading);
                this.map.setHeading(this.heading);
                break;
              default:
                break;
            }
          } ,
        error(err) {
           this.latitude=0;//17.6599;
            this.longitude=0;//75.9064;
            
            
            try{
                if(localStorage['latLong'] && !this.longitude && !this.latitude){
                    // alert(this.longitude);
                    var loc_arry=localStorage['latLong'].split(',');
                    this.latitude=parseFloat(loc_arry[0]);
                    this.longitude=parseFloat(loc_arry[1]);
                    
                }
            }catch (error) {}
            
            
            
             if(this.user_Details && this.user_Details.default_lat && !this.latitude){
                        this.latitude=parseFloat(this.user_Details.default_lat);
                        localStorage['latLong']=this.user_Details.default_lat+','+this.user_Details.default_lng;
             }
             if(this.user_Details && this.user_Details.default_lng && !this.longitude)
                        this.longitude=parseFloat(this.user_Details.default_lng);
            
            
            
            
            // alert(this.latitude);
            // this.latitude=15.8623;
            // this.longitude=73.632004;
            
            
             if(!this.isLocationAcepted){
                 this.isLocationAcepted=true;
                 this.getLocation();
                 this.initMap();
             }
                
             this.isLoading=false;
        }, 
        success(position) {
            this.latitude  = position.coords.latitude;
            this.longitude = position.coords.longitude; 
            this.isLocationAcepted=true;
            this.initMap();
        },
    initMap() {
        // alert(this.latitude);
        var self=this;
          self.map = new google.maps.Map(this.$refs.map, {
                center: { lat:self.latitude, lng: self.longitude },
                zoom: 21,
                mapTypeId: google.maps.MapTypeId.SATELLITE,
                gestureHandling: 'greedy',  // Allow all gestures
                heading: 320,
                tilt: 45  // Map tilt
                
          });
      // 
        if(self.user_Details.kmlurl){
            //alert(self.$hostname+self.user_Details.kmlurl);
             const kmlLayer = new google.maps.KmlLayer({
                    url: self.$hostname+self.user_Details.kmlurl, // Replace with the URL of your KML file
                    map: self.map,
                    preserveViewport: true,
                    suppressInfoWindows: true
                     
          });
          
           
      // 
    
          kmlLayer.setMap(this.map);
          //self.addTouchEvents();
        }
      
      this.placeMarker(new google.maps.LatLng(self.latitude, self.longitude));

      this.map.addListener('click', (event) => {
            self.latitude  = event.latLng.lat();
          self.longitude = event.latLng.lng();  
        this.placeMarker(event.latLng);
      });
     
     google.maps.event.addListener(self.marker, 'dragend', function(event) {
          self.latitude  = event.latLng.lat();
          self.longitude = event.latLng.lng();  
         self.placeMarker(event.latLng);
      });
      
      
                 
      if(self.last_pins){
           //  alert(self.last_pins);
              for (var i = 0; i < self.last_pins.length; i++) {
                   var record= self.last_pins[i];
                 //  alert(record.tree_location_lng);
                   if(record.tree_location_lat && record.tree_location_lng)
                    self.placePreviousMarker(new google.maps.LatLng(record.tree_location_lat,record.tree_location_lng));
                 
              }
            
      }
      self.isLoading=false;
    },
     addTouchEvents() {
      let initialAngle = 0;

      const getTouchAngle = (event) => {
        const touch1 = event.touches[0];
        const touch2 = event.touches[1];

        const deltaX = touch2.clientX - touch1.clientX;
        const deltaY = touch2.clientY - touch1.clientY;
        return Math.atan2(deltaY, deltaX) * (180 / Math.PI);
      };

      const rotateMap = (angleDelta) => {
        const heading = this.map.getHeading() || 0;
        this.map.setHeading(heading + angleDelta);
      };

      document.getElementById('map').addEventListener('touchstart', (event) => {
        if (event.touches.length === 2) {
          initialAngle = getTouchAngle(event);
        }
      });

      document.getElementById('map').addEventListener('touchmove', (event) => {
        if (event.touches.length === 2) {
          const currentAngle = getTouchAngle(event);
          const angleDelta = currentAngle - initialAngle;
          rotateMap(angleDelta);
          initialAngle = currentAngle;
        }
      });

      document.getElementById('map').addEventListener('touchend', (event) => {
        if (event.touches.length < 2) {
          initialAngle = 0;  // Reset angle when touch ends
        }
      });
    },
    placeMarker(location) {
      if (this.marker) {
        this.marker.setPosition(location);
      } else {
        this.marker = new google.maps.Marker({
          position: location,
          map: this.map,
         draggable: true
        });
      }
 
    },
    placePreviousMarker(location) {
       new google.maps.Marker({
          position: location,
          map: this.map
        });
      
    },
  },
};
</script>

<style>
/* Add any additional styles here */
</style>
