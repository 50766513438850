<template>
    <footer class="footer">
        <div v-show="isMobile" style="display: flex;column-gap: 10px;">
           <router-link class="row text-center text-white" :to="employees.emprole==4? '/frontdash':'/dashboard'">
                    <i class="fa fa-home"></i>
                    <label>Home</label>
            </router-link>
             <router-link class="row text-center text-white" :to="nagarpalika.nptype=='TREE'?'/newsurvey':'/tanksurvey'">
                    <i class="fa fa-edit"></i>
                    <label>Survey</label>
          </router-link>
             <router-link class="row text-center text-white"  :to="nagarpalika.nptype=='TREE'?'/treereport':'/tankreport'">
                    <i class="fa fa-line-chart"></i>
                    <label>Report</label>
         </router-link>
             <router-link class="row text-center text-white" :to="nagarpalika.nptype=='TREE'?'/offlinereport':'/tankreport'">
                    <i class="fa fa-file-o"></i>
                    <label>Offline</label>
          </router-link>
        </div>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 footer-copyright text-center text-white">
                    © PRAPANCH
              </div>
            </div>
          </div>
        </footer>
</template>

<style scopped>
    .footer{
          position: fixed;
        width: -webkit-fill-available;
        bottom: -15px;
        background-color: #00290b;
    }
    .footer label{
        color: black;
            font-size: xx-small;
    }
.footer .fa    {
        font-size: 22px;
    }
    
    .text-white label{
        color: white;
            text-transform: uppercase;
            padding-top: 5px;
    }
</style>
<script>

  import axios from 'axios'; 

export default {
  name: 'FooterPart',
	mounted() {
		this.sidebar();
        this.isMobileFunc();
	},data() {
  return {
    iscalled:0,
     nagarpalika: {logo:''},
    myInstance: null,
    isMobile:false,
      employees: {empname:'',emprole:'0',isMainAdmin:0},
  }
}, 
  created() {
      if(localStorage['token']){
        var self=this;
        axios.post('is_authenticated',{token:localStorage['token']}).then(function (response) {
            if(response.data.is==0){
                  localStorage['token']="";
                  window.location.replace(window.location.origin);
            }
            else{
                self.employees = response.data.user;  
                if(self.employees.emprole==999)
                    self.nagarpalika ={'nptype':'BOTH'};
                else
                    self.nagarpalika = response.data.nagarpalika;
                window.scrollTo(0, 0);
            }
            });

      }else
      this.$router.push('/');
 
  },
    beforeMount() { 
    //  naturesurvey.in  tree.dktsoft.in
      if(!this.isScriptLoaded("//naturesurvey.in/assets/js/sidebar-menu.js")){
            const plugin = document.createElement("script");
            plugin.setAttribute("src","//naturesurvey.in/assets/js/sidebar-menu.js");
            plugin.async = true;
            document.body.appendChild(plugin);

      }
       if(!this.isScriptLoaded("//naturesurvey.in/assets/js/sidebar-pin.js")){
            const plugin2 = document.createElement("script");
            plugin2.setAttribute("src","//naturesurvey.in/assets/js/sidebar-pin.js");
            plugin2.async = true;
            document.body.appendChild(plugin2);
            
      }
            if(!this.isScriptLoaded("//naturesurvey.in/assets/js/script.js")){
            const plugin4 = document.createElement("script");
            plugin4.setAttribute("src","//naturesurvey.in/assets/js/script.js");
            plugin4.async = true;
            document.body.appendChild(plugin4);
            }

            
},
    methods:{
      isScriptLoaded(src)
      {
          return Boolean(document.querySelector('script[src="' + src + '"]'));
      },
    isMobileFunc() {
            if( screen.width <= 460 ) {
                    this.isMobile= true;
                }
                else {
                    this.isMobile= false;
                }
         },
    sidebar(){
       
      if(this.iscalled==0){
          const toggle = document.getElementById("searchIcon");
          let newinput = false;
          toggle.addEventListener("click", function(){
              newinput = !newinput
              if(newinput){
              document.getElementById("searchInput").classList.add("show")
              }else{
              document.getElementById("searchInput").classList.remove("show")
              }
          });
          const pinTitle = document.querySelector(".pin-title");
          let pinIcon = document.querySelectorAll(".sidebar-list .fa-thumb-tack");

      }
      this.iscalled=1;
    }
  }
  
}
</script>
 
