<template>

  <!-- Page Sidebar Ends-->
  <div class="page-body">
  
    <PageTitle title="Dashboard" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row size-column">
        <h4 class="text-primary" v-if="isLoading">Please Wait,Loading Data....</h4>
        <div class="col-xxl-9 box-col-12">
          <div class="row">
            <div class="col-xl-3 col-sm-4" v-if="userDetails.emprole==999">
              <!-- <a href="#" class="card-link"> -->
                <router-link to="/nagarpalika" class="card-link">
              <div class="card o-hidden small-widget bg-info">
                <div class="card-body total-project border-b-primary border-2"><span class="f-light f-w-500 f-14 text-white">Nagarpalika</span>
                  <div class="project-details">
                    <div class="project-counter">
                      <h2 class="f-w-600 text-white">{{nagarpalika_count}}</h2> 
                    </div>
                    <div class="product-sub bg-primary-light">
                      <i class="fa fa-home"></i>
                    </div>
                  </div>
                  
                </div>
              </div>
            </router-link>
              <!-- </a> -->
            </div>
           <!-- <div class="col-xl-3 col-sm-4" v-if="userDetails.emprole==999">
              <router-link to="/survey" class="card-link">
              <div class="card o-hidden small-widget bg-warning">
                <div class="card-body total-Progress border-b-dark border-2"> <span class="f-light f-w-500 f-14 text-white">Survey</span>
                  <div class="project-details">
                    <div class="project-counter">
                      <h2 class="f-w-600 text-dark">{{surveys_count}}</h2> 
                    </div>
                    <div class="product-sub bg-primary-light">
                      <i class="fa fa-list"></i>
                    </div>
                  </div>
                  
                </div>
              </div>
            </router-link>
            </div>-->
            <div class="col-xl-3 col-sm-4">
              <router-link to="/users" class="card-link">
              <div class="card o-hidden small-widget bg-dark">
                <div class="card-body total-Complete border-b-warning border-2"><span
                    class="f-light f-w-500 f-144 text-white">Users</span>
                  <div class="project-details">
                    <div class="project-counter">
                      <h2 class="f-w-6004 text-white">{{users_count}}</h2> 
                    </div>
                    <div class="product-sub bg-secondary-light">
                       <i class="fa fa-users"></i>
                    </div>
                  </div>
                 
                </div>
              </div>
              </router-link>
            </div>
            <div class="col-xl-3 col-sm-4">
                <router-link to="/treereport" class="card-link">
              <div class="card o-hidden small-widget " style="backgroundColor:#20803a">
                <div class="card-body total-project border-b-primary border-2"><span class="f-light f-w-500 f-14 text-white">Tree Survey</span>
                  <div class="project-details">
                    <div class="project-counter">
                      <h2 class="f-w-600 text-white">{{tree_count}}</h2> 
                    </div>
                    <div class="product-sub bg-success-light">
                        <i class="fa fa-tree text-white"></i>
                    </div>
                  </div>
                  
                </div>
              </div>
            </router-link>
            </div>
            
            <div class="col-xl-3 col-sm-4" v-show="tank_count>0">
                <router-link to="/tankreport" class="card-link">
              <div class="card o-hidden small-widget bg-warning"  >
                <div class="card-body total-project border-b-warning border-2"><span class="f-light f-w-500 f-14 text-white">Tank Survey</span>
                  <div class="project-details">
                    <div class="project-counter">
                      <h2 class="f-w-600 text-white">{{tank_count}}</h2> 
                    </div>
                    <div class="product-sub bg-warning-light">
                        <i class="fa fa-tasks text-white"></i>
                    </div>
                  </div>
                  
                </div>
              </div>
            </router-link>
            </div>
            <div class="col-xl-3 col-sm-4" v-show="ward_count>0">
                <router-link to="/tankreport" class="card-link">
              <div class="card o-hidden small-widget " style="backgroundColor:#a12500" >
                <div class="card-body total-project border-b-warning border-2"><span class="f-light f-w-500 f-14 text-white">Ward Count</span>
                  <div class="project-details">
                    <div class="project-counter">
                      <h2 class="f-w-600 text-white">{{ward_count}}</h2> 
                    </div>
                    <div class="product-sub bg-warning-light">
                        <i class="fa fa-tasks text-white"></i>
                    </div>
                  </div>
                  
                </div>
              </div>
            </router-link>
            </div>
            <div class=" row"  >
                   <div class="card">
                          <div class="card-body">
                             <div class="col-xl-12 col-sm-12" style=" height: 200px;">
                                 <h6>Tree Survey</h6>
                                <Bar :data="data" :options="options" />
                             </div>
                        </div>
                    </div>
                    
                 
                     <div class="card">
                          <div class="card-body">
                               <div class=" row">
                                 <div class="col-xl-3 col-12 col-md-3" style="width: 300px; height: 300px;">
                                     <h6>Tree Conditions</h6>
                                  <Pie :data="health_piedata" :options="options"  :key="chartKey" />
                                 </div>
                                 
                                 <div class="col-xl-3 col-12 col-md-3" style="width: 300px; height: 300px;"  :key="chartKey" >
                                     <h6>Tree Ownership</h6>
                                  <Pie :data="owner_piedata" :options="options" />
                                 </div> 
                                 </div> 
                           </div>
                  </div>
                    <div class="card"  v-show="tank_count>0">
                          <div class="card-body">
                             <div class="col-xl-12 col-sm-12" style=" height: 200px;">
                                   <h6>Septic Tank Survey</h6>
                                <Bar :data="tankdata" :options="options" />
                             </div>
                        </div>
                    </div>
                     <div class="card" v-show="tank_count>0">
                          <div class="card-body">
                               <div class=" row">
                                 <div class="col-xl-3 col-12 col-md-3" style="width: 300px; height: 300px;">
                                     <h6>Tank Sanitation</h6>
                                  <Pie :data="sanitation_piedata" :options="options"  :key="chartKey" />
                                 </div>
                                 
                                 <div class="col-xl-3 col-12 col-md-3" style="width: 300px; height: 300px;"  :key="chartKey" >
                                     <h6>Solar</h6>
                                  <Pie :data="solar_piedata" :options="options" />
                                 </div> 
                                  <div class="col-xl-3 col-12 col-md-3" style="width: 300px; height: 300px;"  :key="chartKey" >
                                     <h6>EV</h6>
                                  <Pie :data="ev_piedata" :options="options" />
                                 </div> 
                                 
                                 
                                 </div> 
                           </div>
                  </div>
                  
                   <div class="card">
                          <div class="card-body">
                             <div class="col-xl-6 col-sm-6"> 
                                
                             </div>
                        </div>
                    </div>
             </div>
          </div>
      </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>



</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import axios from 'axios';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,RadarController, RadialLinearScale,
  BarElement,
  CategoryScale,
  LinearScale,ArcElement
} from 'chart.js'
import { Bar,Pie,RadarChart  } from 'vue-chartjs'

ChartJS.register(CategoryScale,ArcElement, LinearScale, BarElement, Title, Tooltip, Legend, RadarController, RadialLinearScale)

export default {
  name: 'HomeView',
  components: {
        PageTitle ,Bar,Pie
    },
    mounted() {
        this.getBasics();

    }, data() {
        return {
            isLoading:true,
            sanitation_piedata:0,
            solar_piedata:0,
            ev_piedata:0,
          nagarpalika_count: 0,
          surveys_count: 0,
          users_count: 0,
          damageCount: 0,
          ward_count: 0,
          x_values: [],
          purchaseStock: [],
          userDetails: {'emprole':0},
          saleStock: [],
          outwardCount: 0,
          tree_count:0,
          tank_count:0,
          chartKey:0,
          tankdata: {
                labels: this.x_values,
                datasets: [{ data: this.purchaseStock},{data: this.saleStock }]
              },
          data: {
                labels: this.x_values,
                datasets: [{ data: this.purchaseStock},{data: this.saleStock }]
              },
              
            health_piedata : {
                  labels: ['Healthy', 'Average', 'Dead'],
                  datasets: [
                    {
                      backgroundColor: ['#003366', '#0066CC', '#92C5F9'],
                      data: [10, 40,21]
                    }
                  ]
                },
                
                 owner_piedata : {
                  labels: ['Government', 'On Road', 'Private'],
                  datasets: [
                    {
                      backgroundColor: ['#732E00', '#CA6C0F', '#F8AE54'],
                      data: [10, 40,21]
                    }
                  ]
                },
                 sanitation_piedata : {
                  labels: ['Septic Tank without Soak Pit', 'Septic Tank with Soak Pit', 'Toilet Not Available'],
                  datasets: [
                    {
                      backgroundColor: ['#732E00', '#CA6C0F', '#F8AE54'],
                      data: [10, 40,21]
                    }
                  ]
                },
                solar_piedata : {
                  labels: ['Yes', 'No'],
                  datasets: [
                    {
                       backgroundColor: ['#003366', '#0066CC'],
                      data: [10, 40]
                    }
                  ]
                },
                
                ev_piedata : {
                  labels: ['Yes', 'No'],
                  datasets: [
                    {
                       backgroundColor: ['#CA6C0F', '#732E00'],
                      data: [10, 40]
                    }
                  ]
                },
                
          options: {
                 maintainAspectRatio: false,
                responsive: true,
              }
        }
    },
    methods: {
      getBasics() {
            var self = this;
            self.isLoading=true;
            axios.post('getDashboard').then(function (response) {
                self.isLoading=false;    
                self.nagarpalika_count  = response.data.nagarpalika_count ; 
                self.surveys_count  = response.data.surveys_count ;
                self.tree_count  = response.data.tree_count ;
                self.users_count  = response.data.users_count ; 
                self.damageCount  = response.data.damage_count ; 
                self.x_values  = response.data.x_values ; 
                self.purchaseStock  = response.data.purchaseStock ; 
                self.saleStock  = response.data.saleStock ; 
                self.userDetails  = response.data.userDetails ; 
                self.tank_count  = response.data.tank_count ; 
                self.ward_count  = response.data.ward_count ; 
                
                self.data= {labels: self.x_values,datasets: response.data.dataset };//[{  backgroundColor: 'green', label: 'Inward Stock',data: self.purchaseStock},{ backgroundColor: '#f87979', label: 'Out-ward Stock',data: self.saleStock }]};
                self.tankdata= {labels: self.x_values,datasets: response.data.tank_dataset };
                
                self.health_piedata.datasets[0].data= [response.data.Healthy,response.data.Average,response.data.Dead];
                
                self.owner_piedata.datasets[0].data= [response.data.Government,response.data.On_Road,response.data.Private];
                 
                self.sanitation_piedata.datasets[0].data= [response.data.sanitation,response.data.sanitationwithout,response.data.toilet];
                self.solar_piedata.datasets[0].data= [response.data.solar_yes,response.data.solar_no];
                self.ev_piedata.datasets[0].data= [response.data.ev_yes,response.data.ev_no];
                 
                self.chartKey++;
                window.scrollTo(0, 0);
            }).catch(error => {
                      self.isLoading=false;    //console.log(error.response.data.error)
                  });
        }
    }
}
</script>
